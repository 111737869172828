define("discourse/plugins/discourse-encrypt/lib/pack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PACKED_KEY_HEADER = _exports.PACKED_KEY_FOOTER = _exports.PACKED_KEY_COLUMNS = void 0;
  _exports.getPackedPlaceholder = getPackedPlaceholder;
  _exports.packIdentity = packIdentity;
  _exports.unpackIdentity = unpackIdentity;
  /*
   * Useful variables for key import and export format.
   */

  const PACKED_KEY_COLUMNS = _exports.PACKED_KEY_COLUMNS = 71;
  const PACKED_KEY_HEADER = _exports.PACKED_KEY_HEADER = "============== BEGIN EXPORTED DISCOURSE ENCRYPT KEY PAIR ==============";
  const PACKED_KEY_FOOTER = _exports.PACKED_KEY_FOOTER = "=============== END EXPORTED DISCOURSE ENCRYPT KEY PAIR ===============";
  function packIdentity(identity) {
    const segments = [];
    segments.push(PACKED_KEY_HEADER);
    for (let i = 0, len = identity.length; i < len; i += PACKED_KEY_COLUMNS) {
      segments.push(identity.substr(i, PACKED_KEY_COLUMNS));
    }
    segments.push(PACKED_KEY_FOOTER);
    return segments.join("\n");
  }
  function unpackIdentity(identity) {
    let ret = identity.replace(PACKED_KEY_HEADER, "").replace(PACKED_KEY_FOOTER, "").split(/\s+/).map(x => x.trim()).join("");

    // Backwards compatibility pre-refactoring.
    const PACKED_KEY_SEPARATOR = "-----------------------------------------------------------------------";
    if (ret.indexOf(PACKED_KEY_SEPARATOR) !== -1) {
      ret = "0$" + ret.split(PACKED_KEY_SEPARATOR).join("$");
    }
    return ret;
  }
  function getPackedPlaceholder() {
    return PACKED_KEY_HEADER + "\n" + (".".repeat(PACKED_KEY_COLUMNS) + "\n").repeat(3) + PACKED_KEY_FOOTER;
  }
});