define("discourse/plugins/discourse-encrypt/discourse/components/encrypted-post-timer-dropdown", ["exports", "@ember/object", "@ember/object/computed", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _object, _computed, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIMER_OPTIONS = [{
    id: "",
    name: _I18n.default.t("encrypt.time_bomb.never")
  }, {
    id: "3",
    name: _I18n.default.t("encrypt.time_bomb.3_minutes")
  }, {
    id: "60",
    name: _I18n.default.t("encrypt.time_bomb.1_hour")
  }, {
    id: "180",
    name: _I18n.default.t("encrypt.time_bomb.3_hours")
  }, {
    id: "720",
    name: _I18n.default.t("encrypt.time_bomb.12_hours")
  }, {
    id: "1440",
    name: _I18n.default.t("encrypt.time_bomb.24_hours")
  }, {
    id: "4320",
    name: _I18n.default.t("encrypt.time_bomb.3_days")
  }, {
    id: "10080",
    name: _I18n.default.t("encrypt.time_bomb.7_days")
  }];
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: ["encrypted-post-timer-dropdown"],
    classNameBindings: ["hidden:hidden"],
    selectKitOptions: {
      icon: "discourse-trash-clock",
      showFullTitle: true
    },
    content: (0, _object.computed)("topicDeleteAt", function () {
      if (this.topicDeleteAt) {
        return TIMER_OPTIONS.filter(option => {
          return option.id.length > 0 && moment().add(option.id, "minutes") < moment(this.topicDeleteAt);
        });
      } else {
        return TIMER_OPTIONS;
      }
    }),
    hidden: (0, _computed.empty)("content")
  });
});