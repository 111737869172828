define("discourse/plugins/discourse-encrypt/lib/protocol", ["exports", "@ember/test", "rsvp", "discourse-common/config/environment", "discourse/plugins/discourse-encrypt/lib/base64", "discourse/plugins/discourse-encrypt/lib/database", "discourse/plugins/discourse-encrypt/lib/protocol-v0", "discourse/plugins/discourse-encrypt/lib/protocol-v1"], function (_exports, _test, _rsvp, _environment, _base, _database, _protocolV, _protocolV2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENCRYPT_PROTOCOL_VERSION = void 0;
  _exports.decrypt = decrypt;
  _exports.encrypt = encrypt;
  _exports.exportIdentity = exportIdentity;
  _exports.exportKey = exportKey;
  _exports.generateIdentity = generateIdentity;
  _exports.generateKey = generateKey;
  _exports.importIdentity = importIdentity;
  _exports.importKey = importKey;
  _exports.verify = verify;
  let pendingOperationsCount = 0;
  if ((0, _environment.isTesting)()) {
    (0, _test.registerWaiter)(() => pendingOperationsCount === 0);
  }

  /**
   * @var {Number} ENCRYPT_PROTOCOL_VERSION Current protocol version.
   */
  const ENCRYPT_PROTOCOL_VERSION = _exports.ENCRYPT_PROTOCOL_VERSION = 1;

  /*
   * Identity management
   */

  /**
   * Generates a user identity.
   *
   * @return {Promise}
   */
  function generateIdentity(version) {
    version ??= ENCRYPT_PROTOCOL_VERSION;
    let promise = _rsvp.Promise.reject();
    if (version === 0) {
      promise = (0, _protocolV.generateIdentity)().then(id => ({
        encryptPublic: id.publicKey,
        encryptPrivate: id.privateKey
      }));
    } else if (version === 1) {
      promise = (0, _protocolV2.generateIdentity)();
    }
    pendingOperationsCount++;
    return promise.then(id => {
      id.version = version;
      return id;
    }).finally(() => pendingOperationsCount--);
  }

  /**
   * Exports a user identity. If a passphrase is given, then the result will be
   * encrypted.
   *
   * @param {Object} identity
   * @param {String} passphrase
   *
   * @return {Promise<String | { public: String, private: String }>}
   */
  function exportIdentity(identity, passphrase) {
    let promise = _rsvp.Promise.reject();
    if (identity.version === 0) {
      promise = (0, _protocolV.exportIdentity)({
        publicKey: identity.encryptPublic,
        privateKey: identity.encryptPrivate
      }, passphrase);
    } else if (identity.version === 1) {
      promise = (0, _protocolV2.exportIdentity)(identity, passphrase);
    }
    pendingOperationsCount++;
    return promise.then(exported => ({
      public: identity.version + "$" + exported.public,
      private: identity.version + "$" + exported.private
    })).finally(() => pendingOperationsCount--);
  }

  /**
   * Imports a user identity.
   *
   * @param {Object} identity
   * @param {String} passphrase
   * @param {Boolean} extractable
   *
   * @return {Promise}
   */
  function importIdentity(identity, passphrase, extractable) {
    // HACK: Since paper keys can be generated at any time, keys must be
    // extractable at all times (the same behaviour required in Safari).
    extractable = !!extractable || _database.useLocalStorage || true;
    const sep = identity.indexOf("$");
    const version = parseInt(identity.substr(0, sep), 10);
    identity = identity.substr(sep + 1);
    let promise = _rsvp.Promise.reject();
    if (version === 0) {
      promise = (0, _protocolV.importIdentity)(identity, passphrase, extractable).then(id => ({
        encryptPublic: id.publicKey,
        encryptPrivate: id.privateKey
      }));
    } else if (version === 1) {
      promise = (0, _protocolV2.importIdentity)(identity, passphrase, extractable);
    }
    pendingOperationsCount++;
    return promise.then(id => {
      id.version = version;
      return id;
    }).finally(() => pendingOperationsCount--);
  }

  /*
   * Encryption, decryption and verification
   */

  /**
   * Encrypts an object.
   *
   * @param {CryptoKey} key
   * @param {String|Object} data
   * @param {{ includeUploads: Boolean,
   *           signKey: CryptoKey,
   *           version: Number }} opts
   *
   * @return {Promise<String>}
   */
  function encrypt(key, data, opts) {
    // Build extra information that will be appended to the ciphertext. This
    // extra information is _not_ encrypted and it is required for various
    // features.
    //
    // For example, links to uploads must be visible, otherwise the server will
    // attempt to remove orphaned uploads.
    let extra = "";
    if (opts && opts.includeUploads) {
      const uploads = data.raw.match(/upload:\/\/[A-Za-z0-9\.]+/g);
      if (uploads) {
        extra += "\n" + uploads.map(upload => `[](${upload})`).join();
      }
    }
    const version = opts && opts.version || ENCRYPT_PROTOCOL_VERSION;
    let promise = _rsvp.Promise.reject();
    if (version === 0) {
      promise = (0, _protocolV.encrypt)(key, typeof data === "object" ? data.raw : data);
    } else if (version === 1) {
      promise = (0, _protocolV2.encrypt)(key, opts && opts.signKey, data);
    }
    pendingOperationsCount++;
    return promise.then(ciphertext => version + "$" + ciphertext + extra).finally(() => pendingOperationsCount--);
  }

  /**
   * Decrypts a message.
   *
   * @param {CryptoKey} key
   * @param {String} ciphertext
   *
   * @return {Promise<Object>}
   */
  function decrypt(key, ciphertext) {
    ciphertext = ciphertext.split("\n")[0];
    const sep = ciphertext.indexOf("$");
    const version = parseInt(ciphertext.substr(0, sep), 10);
    ciphertext = ciphertext.substr(sep + 1);
    if (version === 0) {
      pendingOperationsCount++;
      return (0, _protocolV.decrypt)(key, ciphertext).then(plaintext => ({
        raw: plaintext
      })).finally(() => pendingOperationsCount--);
    } else if (version === 1) {
      pendingOperationsCount++;
      return (0, _protocolV2.decrypt)(key, ciphertext).finally(() => pendingOperationsCount--);
    }
    return _rsvp.Promise.reject();
  }

  /**
   * Verifies the integrity and signature of a message.
   *
   * @param {CryptoKey} key
   * @param {String|Object} plaintext
   * @param {String} ciphertext
   */
  function verify(key, plaintext, ciphertext) {
    ciphertext = ciphertext.split("\n")[0];
    const sep = ciphertext.indexOf("$");
    const version = parseInt(ciphertext.substr(0, sep), 10);
    ciphertext = ciphertext.substr(sep + 1);
    if (version === 0) {
      return _rsvp.Promise.resolve(null);
    } else if (version === 1) {
      pendingOperationsCount++;
      return (0, _protocolV2.verify)(key, plaintext, ciphertext).finally(() => pendingOperationsCount--);
    }
    return _rsvp.Promise.reject();
  }

  /*
   * Key management
   */

  /**
   * Generates a symmetric key used to encrypt topic keys.
   *
   * @return {Promise<CryptoKey>}
   */
  function generateKey() {
    pendingOperationsCount++;
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.generateKey({
        name: "AES-GCM",
        length: 256
      }, true, ["encrypt", "decrypt"]).then(resolve, reject).finally(() => pendingOperationsCount--);
    });
  }

  /**
   * Exports and wraps a symmetric key.
   *
   * @param {CryptoKey} key
   * @param {CryptoKey} publicKey
   *
   * @return {Promise<String>}
   */
  function exportKey(key, publicKey) {
    pendingOperationsCount++;
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.wrapKey("raw", key, publicKey, {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }).then(wrapped => (0, _base.bufferToBase64)(wrapped)).then(resolve, reject).finally(() => pendingOperationsCount--);
    });
  }

  /**
   * Unwraps and imports a symmetric key.
   *
   * @param {CryptoKey} key
   * @param {CryptoKey} privateKey
   *
   * @return {Promise<CryptoKey>}
   */
  function importKey(key, privateKey) {
    pendingOperationsCount++;
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.unwrapKey("raw", (0, _base.base64ToBuffer)(key), privateKey, {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }, {
        name: "AES-GCM",
        length: 256
      }, true, ["encrypt", "decrypt"]).then(resolve, reject).finally(() => pendingOperationsCount--);
    });
  }
});