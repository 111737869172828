define("discourse/plugins/discourse-encrypt/lib/uploads", ["exports", "rsvp", "discourse/lib/uploads"], function (_exports, _rsvp, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadEncryptedFile = downloadEncryptedFile;
  _exports.generateUploadKey = generateUploadKey;
  _exports.getMetadata = getMetadata;
  _exports.readFile = readFile;
  function getMetadata(file, siteSettings) {
    if (!(0, _uploads.isImage)(file.name)) {
      return _rsvp.Promise.resolve({
        original_filename: file.name
      });
    }
    return new _rsvp.Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = err => reject(err);
      img.src = window.URL.createObjectURL(file);
    }).then(img => {
      let ratio = 1;
      if (img.width > siteSettings.max_image_width || img.height > siteSettings.max_image_height) {
        ratio = Math.min(siteSettings.max_image_width / img.width, siteSettings.max_image_height / img.height);
      }
      return {
        original_filename: file.name,
        url: img.src,
        width: img.width,
        height: img.height,
        thumbnail_width: Math.floor(img.width * ratio),
        thumbnail_height: Math.floor(img.height * ratio)
      };
    });
  }
  function readFile(file) {
    return new _rsvp.Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = err => reject(err);
      reader.readAsArrayBuffer(file);
    });
  }
  function generateUploadKey() {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.generateKey({
        name: "AES-GCM",
        length: 256
      }, true, ["encrypt", "decrypt"]).then(resolve, reject);
    });
  }
  function downloadEncryptedFile(url, keyPromise, opts) {
    opts = opts || {};
    const downloadPromise = new _rsvp.Promise((resolve, reject) => {
      let req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
      req.onload = function () {
        let filename = req.getResponseHeader("Content-Disposition");
        if (filename) {
          // Requires Access-Control-Expose-Headers: Content-Disposition.
          filename = filename.match(/filename="(.*?)"/)[1];
        }
        resolve({
          buffer: req.response,
          filename
        });
      };
      req.onerror = reject;
      req.send();
    });
    return _rsvp.Promise.all([keyPromise, downloadPromise]).then(_ref => {
      let [key, download] = _ref;
      const iv = download.buffer.slice(0, 12);
      const content = download.buffer.slice(12);
      return new _rsvp.Promise((resolve, reject) => {
        window.crypto.subtle.decrypt({
          name: "AES-GCM",
          iv,
          tagLength: 128
        }, key, content).then(resolve, reject);
      }).then(buffer => ({
        blob: new Blob([buffer], {
          type: opts.type || "application/x-binary"
        }),
        name: download.filename
      }));
    });
  }
});