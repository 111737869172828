define("discourse/plugins/discourse-encrypt/discourse/initializers/auto-enable-encrypt", ["exports", "discourse/plugins/discourse-encrypt/lib/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AUTO_ENABLE_KEY = "discourse-encrypt-auto-enable";
  var _default = _exports.default = {
    name: "auto-enable-encrypt",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.auto_enable_encrypt) {
        return;
      }
      const currentUser = container.lookup("service:current-user");
      if (currentUser) {
        if (!window.localStorage.getItem(AUTO_ENABLE_KEY) && (0, _discourse.getEncryptionStatus)(currentUser) === _discourse.ENCRYPT_DISABLED) {
          window.localStorage.setItem(AUTO_ENABLE_KEY, true);
          (0, _discourse.enableEncrypt)(currentUser).then(() => {
            const appEvents = container.lookup("service:app-events");
            appEvents.trigger("encrypt:status-changed");
          });
        }
      } else {
        window.localStorage.removeItem(AUTO_ENABLE_KEY);
      }
    }
  };
});