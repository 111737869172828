define("discourse/plugins/discourse-encrypt/lib/protocol-v0", ["exports", "rsvp", "discourse/plugins/discourse-encrypt/lib/base64"], function (_exports, _rsvp, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._bufferToString = _bufferToString;
  _exports._exportPrivateKey = _exportPrivateKey;
  _exports._exportPublicKey = _exportPublicKey;
  _exports._getPassphraseKey = _getPassphraseKey;
  _exports._getSalt = _getSalt;
  _exports._importPrivateKey = _importPrivateKey;
  _exports._importPublicKey = _importPublicKey;
  _exports._stringToBuffer = _stringToBuffer;
  _exports.decrypt = decrypt;
  _exports.encrypt = encrypt;
  _exports.exportIdentity = exportIdentity;
  _exports.generateIdentity = generateIdentity;
  _exports.importIdentity = importIdentity;
  /**
   * Converts a string to a bytes array.
   *
   * @param {String} str
   *
   * @return {Uint16Array}
   */
  function _stringToBuffer(str) {
    let buffer = new ArrayBuffer(str.length * 2);
    let array = new Uint16Array(buffer);
    for (let i = 0; i < str.length; ++i) {
      array[i] = str.charCodeAt(i);
    }
    return buffer;
  }

  /**
   * Converts a bytes array to a string.
   *
   * @param {Uint16Array} buffer
   *
   * @return {String}
   */
  function _bufferToString(buffer) {
    return new TextDecoder("UTF-16").decode(buffer);
  }

  /**
   * Exports a public key.
   *
   * @param {CryptoKey} publicKey
   *
   * @return {Promise<String>}
   */
  function _exportPublicKey(publicKey) {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.exportKey("jwk", publicKey).then(jwk => (0, _base.bufferToBase64)(_stringToBuffer(JSON.stringify(jwk)))).then(resolve, reject);
    });
  }

  /**
   * Imports a public key.
   *
   * @param {String} publicKey
   * @param {Array<String>} usages
   * @param {Boolean} extractable
   *
   * @return {Promise<CryptoKey>}
   */
  function _importPublicKey(publicKey, usages, extractable) {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.importKey("jwk", JSON.parse(_bufferToString((0, _base.base64ToBuffer)(publicKey))), {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }, extractable, usages ? usages : ["encrypt", "wrapKey"]).then(resolve, reject);
    });
  }

  /**
   * Exports a private key to a string, but encrypts it first.
   *
   * @param {CryptoKey} privateKey
   * @param {CryptoKey} key         Key used to encrypt `privateKey`.
   *
   * @return {Promise<String>}
   */
  function _exportPrivateKey(privateKey, key) {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.wrapKey("jwk", privateKey, key, {
        name: "AES-GCM",
        iv
      }).then(buffer => (0, _base.bufferToBase64)(iv) + (0, _base.bufferToBase64)(buffer)).then(resolve, reject);
    });
  }

  /**
   * Imports a private key.
   *
   * @param {CryptoKey} privateKey
   * @param {CryptoKey} key         Key used to decrypt `privateKey`.
   * @param {Boolean}   extractable Whether imported key can be further exported or not.
   *
   * @return {Promise<CryptoKey>}
   */
  function _importPrivateKey(privateKey, key, extractable) {
    const iv = (0, _base.base64ToBuffer)(privateKey.substring(0, 16));
    const wrapped = (0, _base.base64ToBuffer)(privateKey.substring(16));
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.unwrapKey("jwk", wrapped, key, {
        name: "AES-GCM",
        iv
      }, {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }, extractable, ["decrypt", "unwrapKey"]).then(resolve, reject);
    });
  }

  /*
   * Key generation
   */

  function _getSalt() {
    return (0, _base.bufferToBase64)(window.crypto.getRandomValues(new Uint8Array(16)));
  }
  function _getPassphraseKey(passphrase, salt) {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.importKey("raw", _stringToBuffer(passphrase), {
        name: "PBKDF2"
      }, false, ["deriveBits", "deriveKey"]).then(key => window.crypto.subtle.deriveKey({
        name: "PBKDF2",
        salt: (0, _base.base64ToBuffer)(salt),
        iterations: 128000,
        hash: "SHA-256"
      }, key, {
        name: "AES-GCM",
        length: 256
      }, false, ["wrapKey", "unwrapKey"])).then(resolve, reject);
    });
  }
  function generateIdentity() {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.generateKey({
        name: "RSA-OAEP",
        modulusLength: 4096,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: {
          name: "SHA-256"
        }
      }, true, ["encrypt", "decrypt", "wrapKey", "unwrapKey"]).then(keyPair => ({
        publicKey: keyPair.publicKey,
        privateKey: keyPair.privateKey
      })).then(resolve, reject);
    });
  }
  function exportIdentity(identity, passphrase) {
    if (passphrase) {
      const salt = _getSalt();
      return _rsvp.Promise.all([_exportPublicKey(identity.publicKey), _getPassphraseKey(passphrase, salt).then(key => _exportPrivateKey(identity.privateKey, key))]).then(_ref => {
        let [publicKey, privateKey] = _ref;
        return {
          public: publicKey,
          private: publicKey + "$" + privateKey + "$" + salt
        };
      });
    } else {
      return _rsvp.Promise.all([_exportPublicKey(identity.publicKey), _exportPublicKey(identity.privateKey)]).then(_ref2 => {
        let [publicKey, privateKey] = _ref2;
        return {
          public: publicKey,
          private: publicKey + "$" + privateKey
        };
      });
    }
  }
  function importIdentity(identity, passphrase, extractable) {
    if (passphrase) {
      const [publicStr, privateStr, salt] = identity.split("$");
      return _rsvp.Promise.all([_importPublicKey(publicStr, null, extractable), _getPassphraseKey(passphrase, salt).then(key => _importPrivateKey(privateStr, key, extractable))]).then(_ref3 => {
        let [publicKey, privateKey] = _ref3;
        return {
          publicKey,
          privateKey
        };
      });
    } else {
      const [publicStr, privateStr] = identity.split("$");
      return _rsvp.Promise.all([_importPublicKey(publicStr, null, extractable), privateStr ? _importPublicKey(privateStr, ["decrypt", "unwrapKey"], extractable) : undefined]).then(_ref4 => {
        let [publicKey, privateKey] = _ref4;
        return {
          publicKey,
          privateKey
        };
      });
    }
  }

  /*
   * Encryption, decryption and verification
   */

  function encrypt(key, plaintext) {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const buffer = _stringToBuffer(plaintext);
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, buffer).then(encrypted => (0, _base.bufferToBase64)(iv) + (0, _base.bufferToBase64)(encrypted)).then(resolve, reject);
    });
  }
  function decrypt(key, ciphertext) {
    const iv = (0, _base.base64ToBuffer)(ciphertext.substring(0, 16));
    const encrypted = (0, _base.base64ToBuffer)(ciphertext.substring(16));
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.decrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, encrypted).then(buffer => _bufferToString(buffer)).then(resolve, reject);
    });
  }
});