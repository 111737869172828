define("discourse/plugins/discourse-encrypt/discourse/initializers/fetch-encrypt-keys", ["exports", "@ember/application", "rsvp", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/preload-store", "discourse-common/lib/icon-library", "discourse/plugins/discourse-encrypt/lib/discourse"], function (_exports, _application, _rsvp, _ajax, _pluginApi, _preloadStore, _iconLibrary, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CACHE_KEY = "discourse-encrypt-bookmark-cache";
  function addEncryptedBookmarksFromCache(session, response, query, username) {
    if (!query) {
      saveBookmarksResponse(session, response);
      return response;
    }
    let cachePromise = _rsvp.Promise.resolve();
    if (!session.get(CACHE_KEY)) {
      const url = `/u/${username}/bookmarks.json`;
      cachePromise = (0, _ajax.ajax)(url).then(resp => saveBookmarksResponse(session, resp));
    }
    return cachePromise.then(() => {
      const bookmarkIds = new Set();
      response?.user_bookmark_list?.bookmarks?.forEach(bookmark => {
        bookmarkIds.add(bookmark.id);
      });
      const cache = session.get(CACHE_KEY);
      cache.forEach(bookmark => {
        if (bookmark.title.toLowerCase().includes(query.toLowerCase())) {
          if (!response?.user_bookmark_list?.bookmarks) {
            response = {
              user_bookmark_list: {
                bookmarks: []
              }
            };
          }
          if (!bookmarkIds.has(bookmark.id)) {
            bookmarkIds.add(bookmark.id);
            response.user_bookmark_list.bookmarks.push(bookmark);
          }
        }
      });
      return response;
    });
  }
  function saveBookmarksResponse(session, response) {
    if (!response?.user_bookmark_list?.bookmarks) {
      return _rsvp.Promise.resolve();
    }
    const cacheObj = {};

    // Keep current cache values
    let cache = session.get(CACHE_KEY);
    if (cache) {
      cache.forEach(bookmark => {
        cacheObj[bookmark.id] = bookmark;
      });
    }
    const promises = [];
    response?.user_bookmark_list?.bookmarks?.forEach(bookmark => {
      if (!bookmark.topic_key) {
        return;
      }
      (0, _discourse.putTopicKey)(bookmark.topic_id, bookmark.topic_key);
      (0, _discourse.putTopicTitle)(bookmark.topic_id, bookmark.encrypted_title);
      promises.push((0, _discourse.getTopicTitle)(bookmark.topic_id).then(title => {
        bookmark.title = title;
        bookmark.fancy_title = `${(0, _iconLibrary.iconHTML)("user-secret")} ${title}`;
        bookmark.excerpt = null;
        cacheObj[bookmark.id] = bookmark;
      }).catch(() => {}));
    });
    return _rsvp.Promise.all(promises).then(() => {
      session.set(CACHE_KEY, Object.values(cacheObj));
    });
  }
  var _default = _exports.default = {
    name: "fetch-encrypt-keys",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) === _discourse.ENCRYPT_DISABLED) {
        return;
      }

      // Go through the `PreloadStore` and look for preloaded topic keys
      for (let storeKey in _preloadStore.default.data) {
        if (storeKey.includes("topic_")) {
          const topic = _preloadStore.default.data[storeKey];
          (0, _discourse.putTopicKey)(topic.id, topic.topic_key);
          (0, _discourse.putTopicTitle)(topic.id, topic.encrypted_title);
          if (topic.related_messages) {
            for (let i = 0; i < topic.related_messages.length; ++i) {
              const relatedTopic = topic.related_messages[i];
              (0, _discourse.putTopicKey)(relatedTopic.id, relatedTopic.topic_key);
              (0, _discourse.putTopicTitle)(relatedTopic.id, relatedTopic.encrypted_title);
            }
          }
        }
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.modifyClass("adapter:notification", {
          pluginId: "fetch-encrypt-keys",
          find() {
            return this._super(...arguments).then(result => {
              result.notifications.forEach(notification => {
                if (notification.topic_key) {
                  (0, _discourse.putTopicKey)(notification.topic_id, notification.topic_key);
                  (0, _discourse.putTopicTitle)(notification.topic_id, notification.encrypted_title);
                }
              });
              return result;
            });
          }
        });
        api.modifyClassStatic("model:topic", {
          pluginId: "fetch-encrypt-keys",
          create(args) {
            if (args && args.topic_key) {
              (0, _discourse.putTopicKey)(args.id, args.topic_key);
              (0, _discourse.putTopicTitle)(args.id, args.encrypted_title);
            }
            return this._super(...arguments);
          }
        });
        api.modifyClass("model:topic", {
          pluginId: "fetch-encrypt-keys",
          updateFromJson(json) {
            if (json.topic_key) {
              (0, _discourse.putTopicKey)(json.id, json.topic_key);
              (0, _discourse.putTopicTitle)(json.id, json.encrypted_title);
            }
            return this._super(...arguments);
          }
        });
        api.modifyClass("route:user-activity-bookmarks", {
          pluginId: "fetch-encrypt-keys",
          _loadBookmarks(params) {
            return this._super(...arguments).then(response => {
              return addEncryptedBookmarksFromCache((0, _application.getOwner)(this).lookup("service:session"), response, params.q, (0, _application.getOwner)(this).lookup("service:current-user").username);
            });
          }
        });
      });
    }
  };
});