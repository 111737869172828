define("discourse/plugins/discourse-encrypt/lib/protocol-v1", ["exports", "rsvp", "discourse/plugins/discourse-encrypt/lib/base64"], function (_exports, _rsvp, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decrypt = decrypt;
  _exports.encrypt = encrypt;
  _exports.exportIdentity = exportIdentity;
  _exports.generateIdentity = generateIdentity;
  _exports.importIdentity = importIdentity;
  _exports.verify = verify;
  /*
   * Key generation
   */

  function getPassphraseKey(passphrase, salt) {
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.importKey("raw", new TextEncoder().encode(passphrase), {
        name: "PBKDF2"
      }, false, ["deriveBits", "deriveKey"]).then(key => window.crypto.subtle.deriveKey({
        name: "PBKDF2",
        salt,
        iterations: 128000,
        hash: "SHA-256"
      }, key, {
        name: "AES-GCM",
        length: 256
      }, false, ["encrypt", "decrypt"])).then(resolve, reject);
    });
  }
  function plaintextToBuffer(plaintext) {
    return new TextEncoder().encode(typeof plaintext === "object" ? JSON.stringify(plaintext, Object.keys(plaintext).sort()) : JSON.stringify(plaintext));
  }
  function generateIdentity() {
    const encryptKeyPromise = window.crypto.subtle.generateKey({
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: {
        name: "SHA-256"
      }
    }, true, ["encrypt", "decrypt", "wrapKey", "unwrapKey"]);
    const signKeyPromise = window.crypto.subtle.generateKey({
      name: "RSA-PSS",
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: {
        name: "SHA-256"
      }
    }, true, ["sign", "verify"]);
    return _rsvp.Promise.all([encryptKeyPromise, signKeyPromise]).then(_ref => {
      let [encryptKey, signKey] = _ref;
      return {
        encryptPublic: encryptKey.publicKey,
        encryptPrivate: encryptKey.privateKey,
        signPublic: signKey.publicKey,
        signPrivate: signKey.privateKey
      };
    });
  }
  function exportIdentity(identity, passphrase) {
    const identityPromise = _rsvp.Promise.all([window.crypto.subtle.exportKey("jwk", identity.encryptPublic), window.crypto.subtle.exportKey("jwk", identity.encryptPrivate), window.crypto.subtle.exportKey("jwk", identity.signPublic), window.crypto.subtle.exportKey("jwk", identity.signPrivate)]).then(_ref2 => {
      let [encryptPublic, encryptPrivate, signPublic, signPrivate] = _ref2;
      return {
        encryptPublic,
        encryptPrivate,
        signPublic,
        signPrivate
      };
    });
    const publicPromise = identityPromise.then(exported => (0, _base.bufferToBase64)(new TextEncoder().encode(JSON.stringify({
      encryptPublic: exported.encryptPublic,
      signPublic: exported.signPublic
    }))));
    let privatePromise;
    if (passphrase) {
      const salt = window.crypto.getRandomValues(new Uint8Array(16));
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      privatePromise = _rsvp.Promise.all([getPassphraseKey(passphrase, salt), identityPromise]).then(_ref3 => {
        let [key, exported] = _ref3;
        return window.crypto.subtle.encrypt({
          name: "AES-GCM",
          iv,
          tagLength: 128
        }, key, new TextEncoder().encode(JSON.stringify(exported)));
      }).then(exported => (0, _base.bufferToBase64)(salt) + (0, _base.bufferToBase64)(iv) + (0, _base.bufferToBase64)(exported));
    } else {
      privatePromise = identityPromise.then(exported => (0, _base.bufferToBase64)(new TextEncoder().encode(JSON.stringify(exported))));
    }
    return _rsvp.Promise.all([publicPromise, privatePromise]).then(_ref4 => {
      let [publicIdentity, privateIdentity] = _ref4;
      return {
        public: publicIdentity,
        private: privateIdentity
      };
    });
  }
  function importIdentity(identity, passphrase, extractable) {
    let decrypted;
    if (passphrase) {
      const salt = (0, _base.base64ToBuffer)(identity.substring(0, 24));
      const iv = (0, _base.base64ToBuffer)(identity.substring(24, 40));
      const encrypted = (0, _base.base64ToBuffer)(identity.substring(40));
      decrypted = getPassphraseKey(passphrase, salt).then(key => window.crypto.subtle.decrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, encrypted));
    } else {
      decrypted = _rsvp.Promise.resolve((0, _base.base64ToBuffer)(identity));
    }
    return decrypted.then(exported => {
      identity = JSON.parse(new TextDecoder().decode(exported));
      return _rsvp.Promise.all([window.crypto.subtle.importKey("jwk", identity.encryptPublic, {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }, !!extractable, ["encrypt", "wrapKey"]), identity.encryptPrivate ? window.crypto.subtle.importKey("jwk", identity.encryptPrivate, {
        name: "RSA-OAEP",
        hash: {
          name: "SHA-256"
        }
      }, !!extractable, ["decrypt", "unwrapKey"]) : undefined, window.crypto.subtle.importKey("jwk", identity.signPublic, {
        name: "RSA-PSS",
        hash: {
          name: "SHA-256"
        }
      }, !!extractable, ["verify"]), identity.signPrivate ? window.crypto.subtle.importKey("jwk", identity.signPrivate, {
        name: "RSA-PSS",
        hash: {
          name: "SHA-256"
        }
      }, !!extractable, ["sign"]) : undefined]).then(_ref5 => {
        let [encryptPublic, encryptPrivate, signPublic, signPrivate] = _ref5;
        return {
          encryptPublic,
          encryptPrivate,
          signPublic,
          signPrivate
        };
      });
    });
  }

  /*
   * Encryption, decryption and verification
   */

  function encrypt(key, signKey, plaintext) {
    let plaintextPromise = signKey ? window.crypto.subtle.sign({
      name: "RSA-PSS",
      saltLength: 32
    }, signKey, plaintextToBuffer(plaintext)).then(signature => {
      plaintext.signature = (0, _base.bufferToBase64)(signature);
      return plaintext;
    }) : _rsvp.Promise.resolve(plaintext);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    return new _rsvp.Promise((resolve, reject) => {
      plaintextPromise.then(unencrypted => window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, plaintextToBuffer(unencrypted))).then(encrypted => (0, _base.bufferToBase64)(iv) + (0, _base.bufferToBase64)(encrypted)).then(resolve, reject);
    });
  }
  function decrypt(key, ciphertext) {
    const iv = (0, _base.base64ToBuffer)(ciphertext.substring(0, 16));
    const encrypted = (0, _base.base64ToBuffer)(ciphertext.substring(16));
    return new _rsvp.Promise((resolve, reject) => {
      window.crypto.subtle.decrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, encrypted).then(buffer => JSON.parse(new TextDecoder().decode(buffer))).then(resolve, reject);
    });
  }
  function verify(key, plaintext) {
    const {
      signature
    } = plaintext;
    delete plaintext.signature;
    return new _rsvp.Promise(resolve => {
      window.crypto.subtle.verify({
        name: "RSA-PSS",
        saltLength: 32
      }, key, (0, _base.base64ToBuffer)(signature), plaintextToBuffer(plaintext)).then(isValid => isValid ? resolve(true) : resolve(false)).catch(() => resolve(false));
    });
  }
});