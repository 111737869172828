define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-user-options", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ENCRYPT_PMS_DEFAULT = "encrypt_pms_default";
  var _default = _exports.default = {
    name: "encrypt-user-options",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (siteSettings.encrypt_enabled) {
          api.addSaveableUserOptionField(ENCRYPT_PMS_DEFAULT);
        }
      });
    }
  };
});