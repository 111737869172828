define("discourse/plugins/discourse-encrypt/discourse/components/encrypt-enable-dropdown", ["exports", "@ember/object", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _object, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: ["encrypt-enable-dropdown"],
    selectKitOptions: {
      icon: "bars",
      showFullTitle: false
    },
    content: (0, _object.computed)("importIdentity", "isEncryptEnabled", function () {
      const content = [];
      content.push({
        id: "import",
        icon: "file-import",
        name: this.importIdentity ? this.isEncryptEnabled ? _I18n.default.t("encrypt.preferences.use_paper_key") : _I18n.default.t("encrypt.preferences.generate_key") : _I18n.default.t("encrypt.preferences.import")
      });
      if (this.isEncryptEnabled) {
        content.push({
          id: "reset",
          icon: "trash-alt",
          name: _I18n.default.t("encrypt.reset.title")
        });
      }
      return content;
    })
  });
});